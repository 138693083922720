import React, { useState } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import MainCakeImage from "../components/main-cake-image";
import {
    FormInputText, FormInputEmail, FormInputTel, FormInputDate
    , FormSelect, FormSelectTime, FormTextArea, FormRadioGroup
    , FormInputAddressAutoComplete, FormButton, FormLinkButton
} from '../components/form-elements';
import Axios from "axios";
import useUpdateEffect from "../hooks/use-update-effect";
import usePickupAddress from "../hooks/use-pickup-address";
import { dateValidation, requiredValidation, emailValidation } from "../helpers/form-validation-helper";
import { Link } from "gatsby"
import { FULFILLMENT } from "../constants";

export default function Customise() {

    const [name, setName] = useState(() => "");
    const nameValidations = [(x) => requiredValidation(x, "Name")];

    const [email, setEmail] = useState(() => "");
    const emailValidations = [(x) => requiredValidation(x, "Email"), emailValidation];

    const [phone, setPhone] = useState(() => "");
    const phoneValidations = [(x) => requiredValidation(x, "Phone")];

    const [occasion, setOccasion] = useState(() => "");
    const occasionValidations = [(x) => requiredValidation(x, "Occasion")];

    const [cakeSize, setCakeSize] = useState(() => "6 INCH");
    const [specialRequest, setSpecialRequest] = useState(() => "");


    const [collectionTime, setCollectionTime] = useState(() => "09:30 AM - 12:30 PM");

    const [collectionDate, setCollectionDate] = useState(() => "");
    const collectionDateValidations = [(x) => requiredValidation(x, "Date"), dateValidation]

    const [fulfillment, setfulfillment] = useState(() => "pickUp");
    const [address, setAddress] = useState(() => "");
    const addressValidations = [(x) => requiredValidation(x, "Address")];

    const [cakeFlavour, setCakeFlavour] = useState(() => "BANANA CARAMEL");

    const [formSubmitted, setFormSubmitted] = useState(() => false);
    const [formSubmitDisabled, setFormSubmitDisabled] = useState(() => true);
    const [formProcessing, setFormProcessing] = useState(() => false);

    const form = {
        name, setName, nameValidations,
        email, setEmail, emailValidations,
        phone, setPhone, phoneValidations,
        occasion, setOccasion, occasionValidations,
        cakeSize, setCakeSize,
        specialRequest, setSpecialRequest,
        collectionTime: collectionTime, setCollectionTime,
        collectionDate: collectionDate, setCollectionDate, collectionDateValidations,
        fulfillment, setfulfillment,
        address, setAddress, addressValidations,
        cakeFlavour, setCakeFlavour,
        setFormSubmitted,
        formSubmitDisabled,
        formProcessing
    }

    const config = {
        baseURL: `${process.env.GATSBY_API_URL}/`,
        headers: {
            'x-api-key': `${process.env.GATSBY_API_KEY}`
            , 'Content-Type': 'application/json'
        },
        timeout: 10000
    }

    const formSubmit = (e) => {
        e.preventDefault();
        setFormProcessing(true);
        const instance = Axios.create(config);
        instance
            .post("/custom-order-request", {
                name,
                email,
                phone,
                occasion,
                cakeSize,
                specialRequest,
                collectionTime,
                collectionDate,
                fulfillment,
                address,
                cakeFlavour
            })
            .then(result => {
                setFormSubmitted(true);
                setFormProcessing(false);
            })
            .catch(err => {
                setFormProcessing(false);
                console.error(err);
                //TODO: Error Page                
            })
    };

    useUpdateEffect(
        () => {
            if (nameValidations.find(f => f(name))) setFormSubmitDisabled(true);
            else if (emailValidations.find(f => f(email))) setFormSubmitDisabled(true);
            else if (phoneValidations.find(f => f(phone))) setFormSubmitDisabled(true);
            else if (occasionValidations.find(f => f(occasion))) setFormSubmitDisabled(true);
            else if (collectionDateValidations.find(f => f(collectionDate))) setFormSubmitDisabled(true);
            else if (fulfillment != "pickUp" && addressValidations.find(f => f(address))) setFormSubmitDisabled(true);
            else setFormSubmitDisabled(false);

        },
        [name, email, phone, occasion, collectionDate, address, fulfillment]
    );

    return (
        <>
            <Seo title="Customise" />
            <BrandHeading title="bespoke cakes" />
            <section className="font-gotu text-justify tracking-wider text-pas-gray">
                {!formSubmitted ?
                    <div className="max-w-3xl mx-auto">
                        <div className="text-center mb-10">
                            <MainCakeImage />
                        </div>
                        <WhenToOrder />
                        <Pricing />
                        <Deposit />
                        <hr className="my-8 md:my-20" />
                        <Form form={form} onSubmit={formSubmit} />
                    </div>
                    :
                    <ThankYou />
                }
            </section>
        </>
    )
}

function Form({ form, onSubmit }) {
    return (
        <div className="font-ovo tracking-wider grid grid-cols-1 gap-2 md:gap-6 max-w-2xl mx-auto mt-8">
            {/* <div className="italic font-light">* please fill out required fields</div> */}
            <FormInputText label={"NAME *"}
                value={form.name} onChange={form.setName} validations={form.nameValidations} />
            <FormInputEmail label={"EMAIL *"}
                value={form.email} onChange={form.setEmail} validations={form.emailValidations} />
            <FormInputTel label={"PHONE *"}
                value={form.phone} onChange={form.setPhone} validations={form.phoneValidations} />
            <FormInputText label={"OCCASION *"}
                value={form.occasion} onChange={form.setOccasion} validations={form.occasionValidations} />
            <FormSelect
                label={"CAKE SIZE *"}
                value={form.cakeSize} onChange={form.setCakeSize}
                options={[
                    { value: "ONE-TIER 6 INCH", label: "ONE-TIER 6 INCH (28 dessert serves / 42 coffee serves)" },
                    { value: "ONE-TIER 8 INCH", label: "ONE-TIER 8 INCH (52 dessert serves / 78 coffee serves)" },
                    { value: "TWO-TIER 4 + 6 INCH", label: "TWO-TIER 4 + 6 INCH (40 dessert serves / 60 coffee serves)" },
                    { value: "TWO-TIER 6 + 8 INCH", label: "TWO-TIER 6 + 8 INCH (80 dessert serves / 120 coffee serves)" },
                    { value: "THREE-TIER 4 + 6 + 8 INCH", label: "THREE-TIER 4 + 6 + 8 INCH (92 dessert serves / 138 coffee serves)" },
                    { value: "THREE-TIER 6 + 8 + 10 INCH", label: "THREE-TIER 6 + 8 + 10 INCH (156 dessert serves / 234 coffee serves)" },
                    { value: "FOUR-TIER 4 + 6 + 8 + 10 INCH", label: "FOUR-TIER 4 + 6 + 8 + 10 INCH (168 dessert serves / 252 coffee serves)" },
                ]}
            />
            <FormRadioGroup
                label={"CAKE FLAVOUR *"}
                value={form.cakeFlavour}
                onChange={form.setCakeFlavour}
                groupName={"cakeFlavour"}
                options={[
                    { value: "CHOCOLATE HAZELNUT", labels: ["CHOCOLATE HAZELNUT", "chocolate sponge - hazelnut praline - dark chocolate cream"] },
                    { value: "CHOCOLATE RASPBERRY", labels: ["CHOCOLATE RASPBERRY", "chocolate sponge - raspberry jam - dark chocolate cream"] },
                    { value: "DARK CHOCOLATE", labels: ["DARK CHOCOLATE", "chocolate sponge - dark chocolate cream"] },
                    { value: "LEMON", labels: ["LEMON", "lemon sponge - lemon curd - lemon cream"] },
                    { value: "MATCHA", labels: ["MATCHA", "matcha sponge - matcha cream"] },
                    { value: "STRAWBERRY VANILLA", labels: ["STRAWBERRY VANILLA", "vanilla sponge - strawberry jam - white chocolate cream"] },
                    { value: "TROPICAL", labels: ["TROPICAL", "vanilla sponge - passionfruit jam - mango cream"] },
                    { value: "VANILLA WHITE CHOCOLATE", labels: ["VANILLA WHITE CHOCOLATE", "vanilla sponge - vanilla white chocolate cream"] }
                ]}
            />
            <AllergenWarning />
            <SpecialRequestInfo specialRequest={form.specialRequest} setSpecialRequest={form.setSpecialRequest} />

            <div className="flex gap-10 mb-2 
                 font-gotu text-sm tracking-wider text-red-800">
                <label className="flex">
                    <FormRadioGroup
                        label={""}
                        value={form.fulfillment}
                        onChange={form.setfulfillment}
                        groupName={"fulfillment"}
                        inline={true}
                        options={[
                            { value: "pickUp", labels: [""] },
                        ]}
                    />
                    <span className="font-ovo text-base tracking-wider text-gray-800 -ml-6 pt-0.5">PICK-UP</span>
                </label>
                <label className="flex">
                    <FormRadioGroup
                        label={""}
                        value={form.fulfillment}
                        onChange={form.setfulfillment}
                        groupName={"fulfillment"}
                        inline={true}
                        options={[
                            { value: "delivery", labels: [""] },
                        ]}
                    />
                    <span className="font-ovo text-base tracking-wider text-gray-800 -ml-6 pt-0.5">DELIVERY</span>
                </label>
            </div>
            <div className="flex justify-between">
                <div className="w-full pr-4">
                    <FormInputDate label={form.fulfillment == FULFILLMENT.PICKUP ? "PICK-UP DATE *" : "DELIVERY DATE *"}
                        value={form.collectionDate}
                        onChange={form.setCollectionDate}
                        validations={form.collectionDateValidations}
                    />
                </div>
                <div className="w-full"> <FormSelectTime
                    label={form.fulfillment == FULFILLMENT.PICKUP ? "PICK-UP TIME" : "DELIVERY TIME"}
                    value={form.collectionTime} onChange={form.setCollectionTime}
                />
                </div>
            </div>
            {
                (form.fulfillment == FULFILLMENT.PICKUP) ?
                    <label className="block">
                        <span className="block text-base tracking-wider text-gray-800 mb-2">{"PICK-UP ADDRESS"}</span>
                        <p className="font-gotu text-xs md:text-[.80rem] tracking-wider text-pas-gray">{usePickupAddress()}</p>
                    </label>
                    :
                    <FormInputAddressAutoComplete
                        label={"DELIVERY ADDRESS *"}
                        value={form.address}
                        onChange={form.setAddress}
                        validations={form.addressValidations} />

            }


            <FormButton
                disabled={form.formSubmitDisabled || form.formProcessing}
                isProcessing={form.formProcessing}
                className={"my-4"}
                label={"SUBMIT"}
                onClick={e => { onSubmit(e) }} />
            <p className="font-gotu italic text-center text-[0.65rem] tracking-wider text-red-500">{form.formSubmitDisabled ? `Please fill out the required (*) fields to enable the SUBMIT button` : ""}</p>
        </div>
    )
}

function AllergenWarning() {
    return (
        <div className="bg-pas-peach-light font-ovo text-center text-xs tracking-wider text-pas-white py-4 my-4 rounded-md">
            {/* <p className="leading-4 px-4">
                <strong>GLUTEN-FREE FRIENDLY OPTIONS AVAILABLE ON REQUEST.</strong>
            </p>
            <p className="leading-4 px-4 pb-4">
                <strong>PLEASE INFORM US BELOW.</strong>
            </p> */}
            {/* <hr className="py-2" />
            <p className="leading-4 text-justify px-4 font-extralight">
                <strong className="font-bold">ALLERGEN WARNING</strong>
                : We take allergies seriously and have designated equipments & utensils when making our gluten friendly cakes. 
                However, while we strive to avoid cross-contamination in preparing our GF friendly cakes, 
                we would like to emphasise that we do handle products containing  
                <span className="font-bold"> gluten, dairy, eggs and soy</span> in our kitchen.
            </p> */}

            <AllergenWarningNoFlavour />
        </div>
    )
}

function AllergenWarningNoFlavour() {
    return (
        <>
            <span className="block font-ovo uppercase text-xs md:text-sm tracking-wider mb-0.5 px-4">Dietary options available on request.</span>
            <span className="block font-ovo uppercase text-xs md:text-sm tracking-wider px-4">Please read our allergen warning <Link to='/faqs#ALLERGIES' className='tracking-wider underline underline-offset-4 decoration-pas-peach-darkest hover:decoration-2'>here</Link>.</span>
        </>
    )
}

function SpecialRequestInfo({ specialRequest, setSpecialRequest }) {

    return (
        <div className="mb-4">
            <span className="block font-ovo tracking-wider text-base text-gray-800 mb-2">SPECIAL REQUESTS *</span>
            <p className="font-gotu text-xs tracking-wider leading-5 md:leading-[1.45rem] text-pas-gray pb-2">
                Do you have a link to your reference photo? A design or a theme? Would you like to add a message or writing on your cake? Feel free to share your requests below, and we will do our best to help!
            </p>
            <FormTextArea label={""} value={specialRequest} onChange={setSpecialRequest} />
        </div>
    )
}

function WhenToOrder() {
    return (
        <div className="font-ovo text-sm md:text-[0.95rem] tracking-wider leading-[1.6rem] text-pas-gray md:mx-5 lg:mx-0">
            <h3 className="mb-9">DO YOU HAVE A PARTICULAR DESIGN IN MIND? Please fill out your details below and let's create your beautiful bespoke cake together!</h3>
            <h3 className="text-base text-black mb-5">WHEN TO ORDER</h3>
            <p>For single and two-tier bespoke cake orders
                , we require <span className="underline underline-offset-2">at least 2 weeks' notice</span> prior to your collection date.
            </p>
            <p className="mb-4">For taller cake orders (three tiers or more)
                , please give us <span className="underline underline-offset-2">3-4 weeks' notice</span> prior to your collection date.
            </p>
            <p className="mb-9">Our single-tier cakes are approx. 7 inches tall with 6 layers. For portion sizes, our dessert serve is approx. 2 x 1 x 3.5 inches and our coffee serve is approx. 2 x 1 x 2 inches. All our cakes are finished with Italian buttercream &/or chocolate spray.
            <span className="text-black"> We do not cover cakes with fondant.</span>
            </p>

        </div>
    )
}

function Pricing() {
    return (
        <>
        <div className="font-ovo text-justify text-sm md:text-[0.95rem] tracking-wider leading-[1.6rem] text-pas-gray md:mx-5 lg:mx-0">
        <h3 className="text-base tracking-wider text-black mb-5">PRICING</h3>
            <p className="mb-9">
                Cake prices may vary according to the complexity of design and special requirements. 
                <span className="text-black"> Our starting price for bespoke cakes is $260</span>
                . Please use our price guide below for an estimate:
            </p>
            <PricingEntry size={"ONE-TIER 6 INCH"} description={"28 dessert serves / 42 coffee serves from $260+"} />            
            <PricingEntry size={"ONE-TIER 8 INCH"} description={"52 dessert serves / 78 coffee serves from $340+"} />
            <PricingEntry size={"TWO-TIER  4 + 6 INCH"} description={"40 dessert serves / 60 coffee serves from $350+"} />
            <PricingEntry size={"TWO-TIER  6 + 8 INCH"} description={"80 dessert serves / 120 coffee serves from $560+"} />
            <PricingEntry size={"THREE-TIER  4 + 6 + 8 INCH"} description={"92 dessert serves / 138 coffee serves from $690+"} />
            <PricingEntry size={"THREE-TIER  6 + 8 + 10 INCH"} description={"156 dessert serves / 234 coffee serves from $1090+"} />
            <PricingEntry size={"FOUR-TIER  4 + 6 + 8 + 10 INCH"} description={"168 dessert serves / 252 coffee serves from $1175+"} />
        </div>
        </>
    )
}

function PricingEntry({ size, description }) {
    return (
        <div className="flex flex-col sm:flex-row mb-4">
            <span className="basis-full sm:basis-5/12 px-1 sm:pr-6 text-center sm:text-right">
                {size}
            </span>
            <span className="basis-full sm:basis-7/12 px-1 sm:pl-6 font-ovo text-center sm:text-left tracking-wider">
                {description}
            </span>
        </div>
    )
}

function Deposit() {
    return (
        <>
        <div className="font-ovo text-justify text-sm md:text-[0.95rem] tracking-wider leading-[1.6rem] text-pas-gray md:mx-5 lg:mx-0 mt-9">
        <h3 className="text-base tracking-wider text-black mb-5">DEPOSIT</h3>
            <p className="mb-4">
                To secure your order and booking date, we require a <span className="italic">50% non-refundable deposit</span> for all bespoke cakes. Orders are only confirmed upon receipt of deposit. Without the deposit, we will not hold your date. 
            </p>
            <p>All remaining balances must be paid within 1 week prior to your pick up/delivery date. If payments are not made by the due date, your cake order may be cancelled.
            </p>
        </div>
        </>
    )
}

function ThankYou() {
    return (
        <>
            <div className="bg-pas-peach text-pas-white 
                            py-[1.62rem]
                            font-ovo text-center text-lg md:text-xl tracking-widest
                            rounded-sm">
                <p className="leading-4 px-4">
                    THANK YOU FOR YOUR ORDER!
                </p>
            </div>
            <div className="text-center text-xs md:text-[.80rem] tracking-wider md:tracking-[.06em] text-pas-gray my-9 md:my-14 mx-1 md:mx-11 lg:mx-56">
                <p className="leading-6 md:leading-8 my-1">You should receive a confirmation email regarding your bespoke cake order shortly.</p>
                <p className="leading-6 md:leading-8 my-1">We will contact you within 48 hours to finalise your cake order.</p>
                <p className="leading-6 md:leading-8 my-7 md:my-1">Please check your spam / junk folder if you haven't received any email from us within this timeframe.</p>
                <p className="leading-6 md:leading-8 my-7 md:my-6">In the meantime, check out the rest of our site for more delightful treats we have in store for you!
                </p>
            </div>
            <div className="flex flex-col max-w-xs mx-auto font-ovo bold text-base md:text-lg text-center tracking-widest">
                <FormLinkButton label={"CONTINUE SHOPPING"} to={"/cakes"} className={"mb-16"} />
            </div>
        </>
    )
}
